@import '../../shared/settings';

.view-map {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border-radius: 8px;
    background-image: url('../../../img/view-map-background.webp');
    background-size: cover;
    background-position: center center;
}

.view-map + .grid-list-pagination {
    margin-top: var(--gutter-size);
}
